import React from 'react';
import { API_URL } from '../helpers/Constants';
import Card from './card';

const Articles = ({
  articles,
  imageType = 'data',
  onChangeCursorText,
  onChangeCursorSubtitle,
}) => {
  // const leftArticlesCount = Math.ceil(articles.length / 5);
  // const leftArticles = articles.slice(0, leftArticlesCount);
  // const rightArticles = articles.slice(leftArticlesCount, articles.length);

  return (
    <div>
      <div>
        {/* <div className='uk-child-width-1-2@s' data-uk-grid='true'> */}
        <div
          style={{
            display: 'grid',
            gridRowGap: 10,
          }}
        >
          {articles.map((article, i) => {
            const imageType2 =
              !article.featuredImage.localFile.childImageSharp
                ?.gatsbyImageData && article.featuredImage.localFile.publicURL
                ? 'video'
                : imageType;
            const imageData =
              imageType2 === 'data'
                ? article.featuredImage.localFile.childImageSharp
                    ?.gatsbyImageData
                : `${process.env.NODE_ENV !== 'production' ? API_URL : ''}${
                    article.featuredImage.url
                  }`;

            return !imageData && !article.featuredImage.localFile.publicURL ? (
              <div />
            ) : imageType2 === 'video' ? (
              <div
                onMouseLeave={() => {
                  onChangeCursorText(null);
                  onChangeCursorSubtitle(null);
                }}
                onMouseOver={() => {
                  onChangeCursorText(article.title);
                  onChangeCursorSubtitle(article.subtitle);
                }}
              >
                {article.featuredImage?.localFile && (
                  <Card
                    link={`/article/${article.slug}`}
                    image={article.featuredImage.localFile.publicURL}
                    imageType={'video'}
                    key={`article__${article.slug}`}
                    style={{
                      width: '100%',
                    }}
                  />
                )}
              </div>
            ) : (
              <div
                onMouseLeave={() => {
                  onChangeCursorText(null);
                  onChangeCursorSubtitle(null);
                }}
                onMouseOver={() => {
                  onChangeCursorText(article.title);
                  onChangeCursorSubtitle(article.subtitle);
                }}
              >
                {article.featuredImage?.localFile && (
                  <Card
                    link={`/article/${article.slug}`}
                    image={imageData}
                    imageType={imageType}
                    key={`article__${article.slug}`}
                    style={{
                      width: '100%',
                    }}
                  />
                )}
              </div>
            );
          })}
        </div>
        {/* <div>
          <div className="uk-child-width-1-2@m uk-grid-match" data-uk-grid>
            {rightArticles.map((article, i) => {
              return (
                <Card
                  article={article}
                  key={`article__right__${article.node.slug}`}
                />
              );
            })}
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Articles;
