import { Link, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import { PageHeader } from '../pages';
import styled from 'styled-components';

export const Logo = ({ imageData, isHovering }) => {
  //   const data = useStaticQuery(query);

  return (
    <Link to='/'>
      <PageHeader style={{ position: 'fixed' }}>
        <StyledGatsbyImage
          image={imageData.localFile.childImageSharp.gatsbyImageData}
          alt={'Fabricius'}
          objectFit={'contain'}
          onMouseEnter={() => isHovering && isHovering(true)}
          onMouseLeave={() => isHovering && isHovering(false)}
        />
      </PageHeader>
    </Link>
  );
};

const StyledGatsbyImage = styled(GatsbyImage)`
  height: 65px;

  @media (max-width: 1200px) {
    height: 50px;
  }
  @media (max-width: 600px) {
    height: 30px;
  }
`;

// const query = graphql`
//   query {
//     strapiGlobal {
//       logo {
//         localFile {
//           childImageSharp {
//             gatsbyImageData(width: 400)
//           }
//         }
//       }
//     }
//   }
// `;
