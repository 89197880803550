import React from 'react';
import styled from 'styled-components';

export const Cursor = ({ displayText, subtitle, active }) => {
  const [position, setPosition] = React.useState({ x: 0, y: 0 });

  React.useEffect(() => {
    const addEventListeners = () => {
      document.addEventListener('mousemove', mMove);
    };

    const removeEventListeners = () => {
      document.removeEventListener('mousemove', mMove);
    };

    const mMove = (el) => {
      setPosition({ x: el.clientX, y: el.clientY });
    };

    addEventListeners();
    return () => removeEventListeners();
  }, []);

  console.log(`active: ${JSON.stringify(active)}`);

  return (
    <StyledCursor
      className={'cursor'}
      id={'cursor'}
      style={{
        left: `${position.x - 20}px`,
        top: `${position.y - 10}px`,
        background: displayText ? 'transparent' : '#ff922c',
        minWidth: displayText ? 'auto' : 40,
        minHeight: displayText ? 'auto' : 40,
        cursor: 'none',
        webkitTransform: `scale(${active ? 0.7 : 1})`,
        transform: `scale(${active ? 0.7 : 1})`,
        display: 'flex',
        flexDirection: 'column',
        whiteSpace: 'nowrap',
      }}
    >
      {displayText || ''}
      <span style={{ fontSize: 22 }}>{subtitle || ''}</span>
    </StyledCursor>
  );
};

const StyledCursor = styled.div`
  position: fixed;
  border: 0;
  border-radius: 100%;
  position: fixed;
  transform: translate(-50%, -50%);
  pointer-events: none;
  color: black;
  text-align: left;
  font-size: 30px;
  z-index: 999999;
  @media (max-width: 1024px) {
    display: none !important;
    cursor: auto !important;
  }
`;
