import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Cursor } from './Cursor';
import styled from 'styled-components';

const Card = ({ link, image, imageType = 'data' }) => {
  const videoRef = React.useRef(null);

  React.useEffect(() => {
    if (!videoRef.current) return;
    videoRef.current.muted = true;
    videoRef.current.defaultMuted = true;
  }, [image]);

  return (
    <>
      <Link to={link} style={{ overflow: 'hidden' }}>
        <CardWrapper className='uk-card uk-card-muted'>
          <div
            className='uk-card-media-top'
            style={{ height: '100%', width: '100%' }}
          >
            {imageType === 'data' ? (
              <StyledGatsbyImage width={'100%'} image={image} alt={``} />
            ) : imageType === 'video' ? (
              <video
                ref={videoRef}
                width={'100%'}
                src={image}
                alt={``}
                autoPlay
                muted
                loop
              />
            ) : (
              <StyledImage
                src={image}
                alt={`Hero image`}
                style={{ objectFit: 'cover', width: '100%' }}
              />
            )}
          </div>
        </CardWrapper>
      </Link>
    </>
  );
};

const CardWrapper = styled.div`
  width: 100%;
  overflow: hidden;
`;

const StyledGatsbyImage = styled(GatsbyImage)`
  min-width: 100%;
  transition: all 0.2s ease;
  &:hover {
    filter: brightness(1.05);
  }
`;
const StyledImage = styled.img`
  min-width: 100%;
  transition: all 0.2s ease;
  &:hover {
    filter: brightness(1.05);
  }
`;

export default Card;
