import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Layout from '../components/layout';
import ArticlesComponent from '../components/articles';
import '../assets/css/main.css';
import styled from 'styled-components';
import { Cursor } from '../components/Cursor';
import { Menu } from '../components/Menu';
import { Logo } from '../components/Logo';

const IndexPage = () => {
  const data = useStaticQuery(query);
  const [cursorText, setCursorText] = React.useState(null);
  const [cursorSubtitle, setCursorSubtitle] = React.useState(null);
  const [isHoveringMenu, setIsHoveringMenu] = React.useState(false);

  return (
    <>
      {/* <Layout seo={data.strapiHomepage.seo}> */}
      <Cursor
        displayText={cursorText}
        subtitle={cursorSubtitle}
        active={isHoveringMenu}
      />

      <Logo
        imageData={data.strapiGlobal.logo}
        isHovering={(hovering) => setIsHoveringMenu(hovering)}
      />
      <ContentContainer className='uk-section'>
        <Menu isHovering={(hovering) => setIsHoveringMenu(hovering)} />
        <ArticlesContainer className='uk-container uk-container-large'>
          {/* <ArticlesComponent
            articles={data.allStrapiArticle.edges}
            style={{ marginTop: -43 }}
            onChangeCursorText={setCursorText}
          /> */}
          <ArticlesComponent
            articles={data.strapiHomepage.workDetails}
            style={{ marginTop: -43 }}
            onChangeCursorText={setCursorText}
            onChangeCursorSubtitle={setCursorSubtitle}
          />
        </ArticlesContainer>
      </ContentContainer>
    </>
  );
};

const query = graphql`
  query {
    strapiGlobal {
      logo {
        localFile {
          childImageSharp {
            gatsbyImageData(width: 500, quality: 100, placeholder: NONE)
          }
        }
      }
    }
    strapiHomepage {
      workDetails {
        ... on StrapiHomepageWorkDetails {
          title
          subtitle
          slug
          featuredImage {
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  quality: 90
                  placeholder: NONE
                )
              }
            }
          }
        }
      }
      seo {
        metaTitle
        metaDescription
        shareImage {
          localFile {
            publicURL
          }
        }
      }
    }
  }
`;

export default IndexPage;

const ContentContainer = styled.div`
  margin-top: 170px;
  @media (max-width: 1200px) {
    margin-top: 150px;
  }
  @media (max-width: 600px) {
    margin-top: 110px;
  }
`;

const ArticlesContainer = styled.div`
  padding-left: 90px;
  padding-right: 90px;
  @media (max-width: 1200px) {
    padding-left: 50px;
    padding-right: 50px;
  }

  @media (max-width: 600px) {
    padding-left: 5px;
    padding-right: 5px;
  }
`;

export const PageHeader = styled.h1`
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
  top: 0;
  height: 100px;
  padding-top: 50px;
  z-index: 1;
  margin: 0;

  @media (max-width: 600px) {
    padding-top: 30px;
  }
`;
