import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import Notifications, { notify } from 'react-notify-toast';
import { Cursor } from './Cursor';

export const Menu = ({ isHovering }) => {
  const DEFAULT_CONTACT_TXT = 'Contact';
  const [contactTxt, setContactTxt] = React.useState(DEFAULT_CONTACT_TXT);
  return (
    <>
      <MenuBar showingEmail={contactTxt !== DEFAULT_CONTACT_TXT}>
        <Link to='/projects' style={{ textAlign: 'left' }}>
          <div
            onMouseEnter={() => isHovering && isHovering(true)}
            onMouseLeave={() => isHovering && isHovering(false)}
          >
            Projects
          </div>
        </Link>
        <Link to='/info' style={{ textAlign: 'center' }}>
          <div
            onMouseEnter={() => isHovering && isHovering(true)}
            onMouseLeave={() => isHovering && isHovering(false)}
          >
            Info
          </div>
        </Link>
        <a
          style={{
            textAlign: 'right',
          }}
          href='mailto:hello@fabriciusdesign.com'
          // onClick={(e) => {
          //   e.preventDefault();
          //   if (contactTxt === DEFAULT_CONTACT_TXT) {
          //     return setContactTxt('hello@fabriciusdesign.com');
          //   }
          //   navigator.clipboard.writeText('hello@fabriciusdesign.com');
          //   notify.show(
          //     `Copied "hello@fabriciusdesign.com" to clipboard!`,
          //     'success',
          //     2000
          //   );
          // }}
        >
          <div
            onMouseEnter={() => isHovering && isHovering(true)}
            onMouseLeave={() => isHovering && isHovering(false)}
          >
            {contactTxt}
          </div>
        </a>
      </MenuBar>
    </>
  );
};

const MenuBar = styled.div`
  position: fixed;
  width: calc(100% - 180px);
  left: 0;
  bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 99;
  font-size: 55px;
  color: black;
  padding: 60px 90px;
  padding-top: 0px;
  overflow: hidden;
  & div {
    font-size: ${({ showingEmail }) => (showingEmail ? `25` : `55`)}px;
  }
  & a,
  div {
    color: black;
    flex: 1;
  }
  & a:hover,
  div:hover {
    color: black;
  }

  & a div {
    display: inline-block;
  }

  @media (max-width: 1200px) {
    width: calc(100% - 20px);
    padding: 10px 10px;
    font-size: 45px;
    & div {
      font-size: ${({ showingEmail }) => (showingEmail ? `20` : `45`)}px;
    }
  }

  @media (max-width: 600px) {
    width: calc(100% - 20px);
    padding: 10px 10px;
    font-size: 30px;
    & div {
      font-size: ${({ showingEmail }) => (showingEmail ? `15` : `30`)}px;
    }
  }
`;
